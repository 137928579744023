.filter-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.filter-button {
  border-radius: 20px;
}

.pending-button.active {
  border: 1px solid #F4C01E; /* Cambia el color del borde según tu diseño */
  background-color: #faffe6; /* Cambia el color de fondo según tu diseño */
}

.pending-button {
  color: #F4C01E ;
}

.pending-button:hover {
  color: #F4C01E !important;
  border-color: #F4C01E !important;
}

.pending-button.active:hover{
  background-color: #faffe6 !important;
}

.approved-button.active {
  border: 1px solid #20CB8A; /* Cambia el color del borde según tu diseño */
  background-color: #e6fff0; /* Cambia el color de fondo según tu diseño */
}

.approved-button {
  color: #20CB8A ;
}

.approved-button:hover {
  color: #20CB8A !important;
  border-color: #20CB8A !important;
}

.approved-button.active:hover{
  background-color: #e6fff0 !important;
}

.rejected-button.active {
  border: 1px solid #BF1824;
  background-color: #ffe6e6;
}

.rejected-button {
  color: #BF1824 ;
}

.rejected-button:hover {
  color: #BF1824 !important;
  border-color: #BF1824 !important;
}

.rejected-button.active:hover{
  background-color: #ffe6e6 !important;
}
