.reports-container {
  width: 100%;
  padding: 16px;
}

.report-card {
  background: #ebf2ff;
  border: none;
  box-shadow: none;
}

.report-card .ant-typography {
  display: block;
}

.report-card .ant-divider {
  margin: 8px 0;
}
