.properties-container {
  width: 100%;
  max-width: 500px; /* Ajusta el max-width según sea necesario */
  background: white; /* Si quieres un fondo blanco */
  padding: 16px; /* Ajusta el padding según sea necesario */
  margin: 0 auto; /* Centra el contenedor horizontalmente */
}

.properties-container img {
  width: 182px;
  height: auto;
}

.properties-container .ant-row {
  margin-bottom: 16px;
}

.carousel-item {
  padding: 3px; /* Espacio entre las imágenes */
}

.carousel-image {
  width: 100%;
  height: auto;
  border-radius: 10px; /* Bordes redondeados */
}

.half-image {
  object-fit: cover; /* Asegura que la imagen se ajuste al contenedor sin distorsión */
  clip-path: inset(0 50% 0 0); /* Recorta la mitad derecha de la imagen */
}

.half-container {
  max-width: "150px";
  overflow: hidden;
}
