.ant-tabs-tab {
  color: #808080 !important;
  font-weight: 300;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #3057D1 !important;
}

.ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 2px solid #3057D1;
  z-index: 2;
}

.approve-button:hover {
  color: white !important ;
  border-color: #3057D1 !important;
}
